<template>
  <div class="backoffice_uploads-vouchers">
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('backofice.uploads.cambio.notification')"></span>
    </el-alert>
    <base-section v-loading="saving">
      <el-form
        ref="form"
        class="cambio-form"
        :model="form"
        @submit.prevent.native="null"
        :key="key"
        autocomplete="off"
        id="appUserForm"
        label-position="top"
      >
        <div>
          <p class="upload-secondary-title" v-html="$t('backofice.uploads.cambio.co2')"></p>
          <upload-wrapper
            accept=".csv, .zip"
            :text="$t('backofice.uploads_wrapper.csv_text')"
            :tips="$t('backofice.uploads_wrapper.csv_warning')"
            :file-list="fileListCo2"
            @on-change="handleUploadCo2"
          ></upload-wrapper>
        </div>
        <div>
          <p class="upload-secondary-title" v-html="$t('backofice.uploads.cambio.invoice')"></p>
          <upload-wrapper
            accept=".xml"
            :text="$t('backofice.uploads_wrapper.xml_warning')"
            :tips="$t('backofice.uploads_wrapper.xml_text')"
            :file-list="fileListInvoice"
            @on-change="handleUploadInvoice"
          ></upload-wrapper>
        </div>
      </el-form>
    </base-section>
      <upload-success-modal
        :title="$t('backoffice.uploads.tabs.cambio')"
        :isVisible="isSuccessModalVisible"
        :data="successModaldata"
        :modalClose="modalClose"
      />
    <upload-error-table
      id="uploadErrorTable"
      :title="$t('user_management.import_users_page.error_title')"
      :validation-errors="validationErrors"
    ></upload-error-table>
  </div>
</template>

<script>
import CambioUploadCo2Service from '@/app/services/UploadService/CambioUploadCo2Service'
import CambioInvoiceUploadService from '@/app/services/UploadService/CambioInvoiceUploadService'
import BackofficeUpload from '@/mixins/BackofficeUpload'

export default {
  name: 'Cambio',
  mixins: [BackofficeUpload],
  data () {
    return {
      fileListCo2: [],
      fileListInvoice: []
    }
  },
  methods: {
    handleUploadCo2 (fileRequest) {
      this.fileListCo2 = [fileRequest]
    },
    handleUploadInvoice (fileRequest) {
      this.fileListInvoice = [fileRequest]
    },
    async handleSaveChanges () {
      this.saving = true
      this.validationErrors = []

      const uploaderCo2 = new CambioUploadCo2Service(
        this.getForm(this.fileListCo2)
      )
      const uploaderInvoice = new CambioInvoiceUploadService(
        this.getForm(this.fileListInvoice)
      )
      try {
        if (this.fileListInvoice.length) {
          this.validationErrors = await uploaderInvoice.upload()
        }
        if (this.fileListCo2.length) {
          this.validationErrors = await uploaderCo2.upload(this.showSuccessModal)
        }
      } catch (e) {
        console.log('import error ', e)
      }
      if (!this.validationErrors || !this.validationErrors.length) {
        this.fileListCo2 = []
        this.fileListInvoice = []
        this.dirty = false
      }
      if (this.validationErrors && this.validationErrors.length) {
        this.$scrollTo('#uploadErrorTable')
      }
      this.saving = false
      this.key = this.$key()
    },
    getForm (fileList) {
      return {
        file: fileList.length ? fileList[0].raw : null
      }
    }
  },
  mounted () {
    this.key = this.$key()
    this.$watch('fileListCo2', {
      deep: true,
      handler () {
        this.dirty = true
        this.validationErrors = []
      }
    })
    this.$watch('fileListInvoice', {
      deep: true,
      handler () {
        this.dirty = true
        this.validationErrors = []
      }
    })
  }
}
</script>
