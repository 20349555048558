<template>
  <div class="backoffice_uploads-vouchers">
    <el-alert type="info" :closable="false" show-icon>
      <span v-html="$t('backofice.uploads.bemobile.notification')"></span>
    </el-alert>
    <base-section v-loading="saving">
      <el-form
        ref="form"
        :model="form"
        @submit.prevent.native="null"
        :key="key"
        autocomplete="off"
        id="appUserForm"
        label-position="top"
      >
        <base-select
          class="kbc__select"
          :label="$t('backofice.uploads.bemobile.select')"
          prop="mrpType"
          :required="true"
          :items="BeMobileTypesList"
          v-model="typeBeMobile"
          name="mrpType"
          :validations="$v.typeBeMobile"
        />
        <p class="upload-secondary-title" v-html="$t('backofice.uploads.bemobile.upload.title')"></p>
        <upload-wrapper
          accept=".csv, .zip, .xlsx"
          :text="$t('backofice.uploads_wrapper.csv_xlsx_text')"
          :tips="$t('backofice.uploads_wrapper.csv_xlsx_warning')"
          :file-list="fileList"
          @on-change="handleUpload"
        ></upload-wrapper>
      </el-form>
    </base-section>
    <upload-success-bemobile-modal
      :title="$t('backoffice.uploads.tabs.bemobile')"
      :isVisible="isSuccessModalVisible"
      :data="successModaldata"
      :modalClose="modalClose"
    />
    <upload-error-table
      id="uploadErrorTable"
      :title="$t('user_management.import_users_page.error_title')"
      :validation-errors="validationErrors"
    ></upload-error-table>
  </div>
</template>

<script>
import UploadService from '@/app/services/UploadService/UploadService'
import BackofficeUpload from '@/mixins/BackofficeUpload'
import { required } from 'vuelidate/lib/validators'
import Api from '@/app/services/ApiService/Api'
import UploadSuccessBemobileModal from '@/components/Backoffice/Uploads/UploadSuccessBemobileModal'

export default {
  name: 'BeMobile',
  mixins: [BackofficeUpload],
  components: { UploadSuccessBemobileModal },
  data () {
    return {
      typeBeMobile: ''
    }
  },
  computed: {
    BeMobileTypesList () {
      return [
        {
          value: 'BeMobile',
          label: 'BeMobile'
        }
      ]
    }
  },
  validations: {
    typeBeMobile: {
      required
    }
  },
  methods: {
    getUploader (form, options) {
      return new UploadService(form, options)
    },
    getForm () {
      return {
        file: this.fileList[0].raw,
        request: new Blob(
          [
            JSON.stringify({
              type: 'BEMOBILE_SALES_RECONCILIATION_UPLOAD',
              targetOspId: this.typeBeMobile
            })
          ],
          { type: 'application/json' }
        )
      }
    },
    async handleSaveChanges () {
      const igronedError = 'FUNCTIONAL_ERROR'
      const options = {
        ignoreErrors: [igronedError],
        onError: e => {
          if (e.getCode() === igronedError) {
            Api.pushMessage(e.getMessage(), 'error')
          }
        }
      }
      this.saving = true
      this.validationErrors = []
      const form = this.getForm()
      const uploader = this.getUploader(form, options)
      this.$v.$touch()
      try {
        if (!this.$v.$invalid) {
          this.validationErrors = await uploader.upload(this.showSuccessModal)
        }
      } catch (e) {
        console.error('import error ', e)
      }
      if (!this.validationErrors) {
        this.fileList = []
      }
      if (this.validationErrors.length) {
        this.$scrollTo('#uploadErrorTable')
      }
      this.saving = false
      this.key = this.$key()
    }
  }
}
</script>
