<template>
  <page-layout
    :title="$t('backoffice.uploads.title')"
    :sub-title="subtitle"
    :has-back-button="true"
    :emit-back="true"
    @back="back"
  >
    <template #header-actions>
      <base-button
        v-if="isMounted"
        :key="key"
        :label="'Upload'"
        type="primary"
        :loading="$refs.routerView.saving"
        :disabled="!$refs.routerView.dirty"
        @click="$refs.routerView.handleSaveChanges"
      ></base-button>
    </template>
    <el-tabs :value="activeName" ref="tabs">
      <el-tab-pane v-for="tab in tabs" :key="tab.name" :name="tab.name">
        <span slot="label" class="flex-span"
          ><i v-if="tab.icon" :class="tab.icon"></i> {{ tab.label }}</span
        >
      </el-tab-pane>
    </el-tabs>
    <router-view ref="routerView" :key="$route.name"></router-view>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import { mapGetters } from 'vuex'
import TabNavigation from '../mixins/TabNavigation'
export default {
  name: 'BackofficeUploadsLayout',
  components: {
    PageLayout
  },
  mixins: [TabNavigation],
  props: {
    emitBack: {
      typ: Boolean,
      default: false
    }
  },
  data () {
    return {
      key: '',
      activeName: '',
      isMounted: false
    }
  },
  watch: {
    '$route.name' () {
      setTimeout(() => {
        this.key = this.$key()
      }, 50)
    }
  },
  methods: {
    back () {
      if (this.$refs.routerView && this.$refs.routerView.goBack) {
        return this.$refs.routerView.goBack()
      } else {
        this.$router.push({ name: 'BackofficeUploads' })
      }
    }
  },
  computed: {
    ...mapGetters({}),
    subtitle () {
      switch (this.$route.name) {
        case 'BackofficeUploadsVouchers': {
          return this.$t('backoffice.uploads.tabs.vouchers')
        }
        case 'BackofficeUploadsSurcharges': {
          return this.$t('backoffice.uploads.tabs.surcharges')
        }
        case 'BackofficeUploadsBlueBikeEvents': {
          return this.$t('backoffice.uploads.tabs.bluebike')
        }
        case 'BackofficeUploadsCambio': {
          return this.$t('backoffice.uploads.tabs.cambio')
        }
        case 'BackofficeUploadsLine': {
          return this.$t('backoffice.uploads.tabs.line')
        }
        case 'BackofficeUploadsKBC': {
          return this.$t('backoffice.uploads.tabs.kbc')
        }
        case 'BackofficeUploadsNMBS': {
          return this.$t('backoffice.uploads.tabs.nmbs')
        }
        case 'BackofficeUploadsSwapfiets': {
          return this.$t('backoffice.uploads.tabs.swapfiets')
        }
        case 'BackofficeUploadsBeMobile': {
          return this.$t('backoffice.uploads.tabs.bemobile')
        }
        case 'BackofficeUploadsCharging': {
          return this.$t('backoffice.uploads.tabs.charging')
        }
      }
      return ''
    },
    tabs () {
      return [
        {
          label: this.$t('backoffice.uploads.tabs.vouchers'),
          name: 'BackofficeUploadsVouchers',
          icon: 'ri-ticket-line'
        },
        {
          label: this.$t('backoffice.uploads.tabs.surcharges'),
          name: 'BackofficeUploadsSurcharges',
          icon: 'ri-line-chart-fill'
        },
        {
          label: this.$t('backoffice.uploads.tabs.bluebike'),
          name: 'BackofficeUploadsBlueBikeEvents',
          icon: 'ri-riding-line'
        },
        {
          label: this.$t('backoffice.uploads.tabs.cambio'),
          name: 'BackofficeUploadsCambio',
          icon: 'ri-car-line'
        },
        {
          label: this.$t('backoffice.uploads.tabs.line'),
          name: 'BackofficeUploadsLine',
          icon: 'ri-bus-line'
        },
        {
          label: this.$t('backoffice.uploads.tabs.kbc'),
          name: 'BackofficeUploadsKBC',
          icon: 'ri-bank-card-2-line'
        },
        {
          label: this.$t('backoffice.uploads.tabs.nmbs'),
          name: 'BackofficeUploadsNMBS',
          icon: 'ri-train-line'
        },
        {
          label: this.$t('backoffice.uploads.tabs.swapfiets'),
          name: 'BackofficeUploadsSwapfiets',
          icon: 'ri-riding-line'
        },
        {
          label: this.$t('backoffice.uploads.tabs.bemobile'),
          name: 'BackofficeUploadsBeMobile',
          icon: 'ri-car-line'
        },
        {
          label: this.$t('backoffice.uploads.tabs.charging'),
          name: 'BackofficeUploadsCharging',
          icon: 'ri-battery-charge-line'
        }
      ]
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.isMounted = true
      this.key = this.$key()
    })
  }
}
</script>

<style lang="scss"></style>
