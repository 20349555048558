<template>
  <div class="backoffice_uploads-vouchers">
    <el-alert
      type="info"
      :closable="false"
      show-icon
    >
    <span v-html="$t('backofice.uploads.swapfiets.notification')"></span>

    </el-alert>
    <base-section v-loading="saving">
      <el-form
        ref="form"
        :model="form"
        @submit.prevent.native="null"
        :key="key"
        autocomplete="off"
        id="appUserForm"
        label-position="top"
      >
        <upload-wrapper
          accept=".csv,.zip"
          :text="$t('backofice.uploads_wrapper.csv_text')"
          :tips="$t('backofice.uploads_wrapper.csv_warning')"
          :file-list="fileList"
          @on-change="handleUpload"
        ></upload-wrapper>
      </el-form>
    </base-section>
     <upload-success-modal
     :title="$t('backoffice.uploads.tabs.swapfiets')"
      :isVisible="isSuccessModalVisible"
      :data="successModaldata"
      :modalClose="modalClose"
    />
    <upload-error-table
      id="uploadErrorTable"
      :title="$t('user_management.import_users_page.error_title')"
      :validation-errors="validationErrors"
    ></upload-error-table>
  </div>
</template>

<script>

import UploadService from '@/app/services/UploadService/UploadService'
import BackofficeUpload from '@/mixins/BackofficeUpload'
export default {
  name: 'Swapfiets',
  mixins: [BackofficeUpload],
  methods: {
    getUploader (form) {
      return new UploadService(form)
    },
    getForm () {
      return {
        file: this.fileList[0].raw,
        request: new Blob([JSON.stringify({
          type: 'SWAPFIETS_INVOICE_UPLOAD',
          targetOspId: null
        })], { type: 'application/json' })
      }
    }
  }
}
</script>
