<template>
  <div class="backoffice_uploads-vouchers">
    <el-alert
      type="info"
      :closable="false"
      show-icon
    >
     <span v-html="$t('backofice.uploads.kbc.notification')"></span>

    </el-alert>
    <base-section v-loading="saving">
      <el-form
        ref="form"
        :model="form"
        @submit.prevent.native="null"
        :key="key"
        autocomplete="off"
        id="appUserForm"
        label-position="top"
      >
        <p class='upload-secondary-title' v-html="$t('backofice.uploads.kbc.title.select')"></p>
         <base-select
            class='kbc__select'
            :label="$t('backofice.uploads.kbc.select')"
             prop="mrpType"
            :required="true"
            :items="kbcTypesList"
            v-model="typeKbc"
            name="mrpType"
            :validations='$v.typeKbc'
      />
        <p class='upload-secondary-title' v-html="$t('backofice.uploads.kbc.upload.title')"></p>
        <upload-wrapper
          accept=".csv,.zip"
          :text="$t('backofice.uploads_wrapper.csv_text')"
          :tips="$t('backofice.uploads_wrapper.csv_warning')"
          :file-list="fileList"
          @on-change="handleUpload"
        ></upload-wrapper>
      </el-form>
    </base-section>
      <upload-success-modal
     :title="$t('backoffice.uploads.tabs.kbc')"
      :isVisible="isSuccessModalVisible"
      :data="successModaldata"
      :modalClose="modalClose"
    />
    <upload-error-table
      id="uploadErrorTable"
      :title="$t('user_management.import_users_page.error_title')"
      :validation-errors="validationErrors"
    ></upload-error-table>
  </div>
</template>

<script>

import UploadService from '@/app/services/UploadService/UploadService'
import BackofficeUpload from '@/mixins/BackofficeUpload'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'KBC',
  mixins: [BackofficeUpload],
  data () {
    return {
      typeKbc: ''
    }
  },
  computed: {
    kbcTypesList () {
      return [
        {
          value: 'kbcbank.CORPORATE_ACCOUNT.reseller',
          label: 'KBC'
        },
        {
          value: 'KKBCGroep.CORPORATE_ACCOUNT.KBCGroep',
          label: 'KBC Groep'
        }
      ]
    }
  },
  validations: {
    typeKbc: {
      required
    }
  },
  methods: {
    getForm () {
      return {
        file: this.fileList[0].raw,
        request: new Blob([JSON.stringify({
          type: 'RESELLER_SALES_RECONCILIATION_UPLOAD',
          targetOspId: this.typeKbc
        })], { type: 'application/json' })
      }
    },
    async handleSaveChanges () {
      this.saving = true
      this.validationErrors = []
      const form = this.getForm()
      const uploader = this.getUploader(form)
      this.$v.$touch()
      try {
        if (!this.$v.$invalid) {
          this.validationErrors = await uploader.upload(this.showSuccessModal)
        }
      } catch (e) {
        console.log('import error ', e)
      }
      if (!this.validationErrors) {
        this.fileList = []
      } else {
        this.$scrollTo('#uploadErrorTable')
      }
      this.saving = false
      this.key = this.$key()
    },
    getUploader (form) {
      return new UploadService(form)
    }
  }
}
</script>
