
import UploadService from '@/app/services/UploadService/UploadService'
import api from '@/api'
import i18n from '@/i18n'

export default class CambioInvoiceUploadService extends UploadService {
  handleUpload () {
    return api.upload.cambioInvoiceUpload(this.formData)
  }

  async upload () {
    const uploadResult = await this.handleUpload()
    await this.handleProcess({ ospId: uploadResult.invoiceOspId })
  }

  handleProcess (ospId) {
    return api.upload.cambioInvoiceProcess(ospId,
      {
        successNotification: i18n.t('uploads.success_notification')
      })
  }
}
