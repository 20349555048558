import UploadService from '@/app/services/UploadService/UploadService'
import api from '@/api'
import i18n from '@/i18n'

export default class CambioUploadCo2Service extends UploadService {
  handleUpload () {
    return api.upload.cambioCo2Upload(this.formData)
  }

  handleProcess (uploadResult, showModal) {
    return api.upload.cambioCo2Process({
      uploadId: uploadResult.upload.uploadId
    }, {
      successNotification: i18n.t('uploads.success_notification'),
      onSuccess: (res) => showModal(res)
    })
  }

  async upload (showModal) {
    const requestId = await this.handleUpload()
    const { validationErrors } = await this.handleProcess(requestId, showModal)
    if (validationErrors && validationErrors.length) {
      return validationErrors
    } else {
      return []
    }
  }
}
