<template>
  <el-dialog
    :title="$t('backoffice.uploads.modal', { type: title })"
    :close-on-click-modal="false"
    :visible.sync="isVisible"
    @close="modalClose"
    width="75%"
    top="8vh"
    class="bemobile-success-dialog"
  >
    <p>
      <span class="counterTotal">{{ data.totalProcessed }}</span>
      {{ this.$t('backoffice.uploads.counter') }}
    </p>
    <br />
    <base-section :title="$t('backoffice.uploads.success_modal.errors')" noPadding :withBorder="false">
      {{ data.errors }}
    </base-section>
    <base-section :title="$t('backoffice.uploads.success_modal.line_with_errors')" noPadding :withBorder="false">
      {{ data.linesWithErrors }}
    </base-section>
    <base-section :title="$t('backoffice.uploads.success_modal.total_processed')" noPadding :withBorder="false">
      {{ data.totalProcessed }}
    </base-section>
    <base-section :title="$t('backoffice.uploads.success_modal.total_with_errors')" noPadding :withBorder="false">
      {{ data.totalWithErrors }}
    </base-section>
    <base-section :title="$t('backoffice.uploads.success_modal.total_without_errors')" noPadding :withBorder="false">
      {{ data.totalWithoutErrors }}
    </base-section>
    <template v-if="this.data.summary">
      <base-section
        :title="$t('backoffice.uploads.success_modal.bemobile.total_amount_in_file')"
        noPadding
        :withBorder="false"
      >
        {{ data.summary.totalAmountInFile }}
      </base-section>
      <base-section
        :title="$t('backoffice.uploads.success_modal.bemobile.total_amount_in_database')"
        noPadding
        :withBorder="false"
      >
        {{ data.summary.totalAmountInDatabase }}
      </base-section>
      <base-section
        :title="$t('backoffice.uploads.success_modal.bemobile.total_sessions_in_file')"
        noPadding
        :withBorder="false"
      >
        {{ data.summary.totalSessionsInFile }}
      </base-section>
      <base-section
        :title="$t('backoffice.uploads.success_modal.bemobile.total_sessions_in_database')"
        noPadding
        :withBorder="false"
      >
        {{ data.summary.totalSessionsInDatabase }}
      </base-section>
      <base-section
        :title="$t('backoffice.uploads.success_modal.bemobile.session_ids_not_in_file')"
        noPadding
        :withBorder="false"
      >
        {{ data.summary.sessionIdsNotInFile.join(', ') }}
      </base-section>
      <base-section
        :title="$t('backoffice.uploads.success_modal.bemobile.session_ids_not_in_database')"
        noPadding
        :withBorder="false"
      >
        {{ data.summary.sessionIdsNotInDatabase.join(', ') }}
      </base-section>
      <base-section
        :title="$t('backoffice.uploads.success_modal.bemobile.session_ids_with_different_price')"
        noPadding
        :withBorder="false"
      >
        {{ data.summary.sessionIdsWithDifferentPrice.join(', ') }}
      </base-section>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: 'UploadSuccessBemobileModal',
  props: {
    title: {
      type: String,
      default: 'Upload success'
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => ({})
    },
    modalClose: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss">
.bemobile-success-dialog {
  .counterTotal {
    color: #139cdc;
  }
  .base-section__content {
    max-height: 150px;
    overflow: auto;
  }
  .base-section__title {
    width: 300px;
  }
}
</style>
