import UploadService from '@/app/services/UploadService/UploadService'
import api from '@/api'
import i18n from '@/i18n'

export default class VoucherUploadService extends UploadService {
  handleUpload () {
    return api.upload.voucherUpload(this.formData)
  }

  handleValidate (requestId) {
    return api.upload.voucherValidate({ uploadId: requestId })
  }

  handleProcess (requestId, showModal) {
    return api.upload.voucherProcess({ uploadId: requestId }, {
      successNotification: i18n.t('uploads.success_notification'),
      onSuccess: (res) => showModal(res)
    })
  }
}
